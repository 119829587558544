<template>
  <div class="card mt-3">
    <div class="field">
      <label for="name">Nome *</label>
      <div class="p-inputgroup">
        <InputText id="name" @blur="v$.despesa.name.$touch"
                   v-model.trim="despesa.name" required="true"
                   maxlength="50"
                   autofocus :class="{'p-invalid': v$.despesa.name.$error}" />
      </div>
      <small class="p-invalid" v-for="error of v$.despesa.name.$errors"
             :key="error.$uid">{{error.$message}}</small>
    </div>
    <div class="field">
      <label for="dtNascimento">Data da Compra *</label>
      <div class="p-inputgroup">
        <Calendar id="dtNascimento" show-icon
                  v-model="despesa.dtPurchase"
                  @blur="v$.despesa.dtPurchase.$touch"
                  autofocus :class="{'p-invalid': v$.despesa.dtPurchase.$error}"
                  required="true"  />
      </div>
      <small class="p-invalid" v-for="error of v$.despesa.dtPurchase.$errors"
             :key="error.$uid">{{error.$message}}</small>
    </div>
<!--    <div class="field">-->
<!--      <label for="categoria">Tipo *</label>-->
<!--      <div class="p-inputgroup">-->
<!--        <span class="p-inputgroup-addon">-->
<!--                        <i class="pi pi-list"></i>-->
<!--                    </span>-->
<!--        <Dropdown id="despesaType" v-model="despesa.inventoryType" :options="despesasType"-->
<!--                  @blur="v$.despesa.inventoryType.$touch"-->
<!--                  option-value="code" optionLabel="name" placeholder="Selecione um tipo de Despesa"-->
<!--                  autofocus :class="{'p-invalid': v$.despesa.inventoryType.$error}"-->
<!--        />-->
<!--      </div>-->
<!--      <small class="p-invalid" v-for="error of v$.despesa.inventoryType.$errors"-->
<!--             :key="error.$uid">{{error.$message}}</small>-->
<!--    </div>-->
    <div class="field" v-show="despesa.inventoryType === 'OUTROS'">
      <label for="other">Outro </label>
      <div class="p-inputgroup">
        <InputText id="other"
                   v-model="despesa.other"
                   maxlength="50" />
      </div>
    </div>
    <div class="field">
      <label for="phone">Descrição</label>
      <div class="p-inputgroup">
        <Textarea maxlength="255" v-model="despesa.description" autofocus rows="5"/>
      </div>
    </div>
  </div>

  <Toolbar class="mb-2">
    <template v-slot:end>
      <div class="my-2">
        <Button label="Cancelar" icon="pi pi-times" class="p-button-outlined mr-2" @click.prevent="$router.push({name: 'desapesas-list'})"/>
        <Button label="Próximo" icon="pi pi-angle-right" icon-pos="right" @click="nextPage()" />
      </div>
    </template>
  </Toolbar>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import InventoryService from "@/service/InventoryService";
import moment from "moment";

export default {
  name: "despesa",
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      despesa: {},
      despesasType: [
        {name: 'COMPRAS', code: 'COMPRAS'}
      ],
      inventoryService: null,
      loader: null
    }
  },
  validations() {
    return {
      despesa: {
        name: {
          required: helpers.withMessage('Campo Nome é obrigatório.', required)
        },
        dtPurchase: {
          required: helpers.withMessage('Campo data despesa é obrigatório.', required)
        }
      }
    }
  },
  created() {
    this.moment = moment;
    moment.locale('pt-BR');
    this.inventoryService = new InventoryService();
  },
  async mounted() {
    this.v$.$reset();
    await this.loadEdit();
  },
  methods: {

    async loadEdit() {
      this.despesaId = this.$route.query.id;
      if(this.despesaId) {
        this.loader = this.$loading.show();
        await this.findById();
        this.loader.hide();
      }
    },

    async findById() {
      const response = await this.inventoryService.findById(this.despesaId);
      if(response.status === 200) {
        this.despesa = response.data;
      }
    },

    async nextPage() {
      const isFormValid = await this.v$.$validate();
      if (isFormValid) {
        this.loader = this.$loading.show();
        this.despesa.id = this.despesaId ? this.despesaId : null;
        this.despesa.inventoryType = 'COMPRAS';
        this.despesa.dtPurchase = moment(this.despesa.dtPurchase, 'DD/MM/YYYY').format('DD/MM/YYYY');
        const response = await this.inventoryService.save(this.despesa);
        if(response.status === 200) {
          this.$emit('next-page', {
            formData: {
              id: response.data.id,
              name: this.despesa.name,
              dtPurchase: this.despesa.dtPurchase,
              inventoryType: this.despesa.inventoryType,
              description: this.despesa.description,
              other: this.despesa.other
            },
            pageIndex: 0,
            despesaId: response.data.id
          });
        }
        this.loader.hide();
      }
    },
  }
}
</script>

<style scoped>

</style>
